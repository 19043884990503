import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const Success = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 10px;
    position: absolute;
    bottom: 5%;
  }

  input {
    width: auto;
    max-width: 80%;
  }

  div {
    margin-bottom: 2rem;
  }

  #toUpload {
    font-size: 22px;
  }

  #linkText {
    font-size: 14px;
    margin-bottom: 0;
  }
`

const StyledImage = styled(Img)`
  height: auto;
  width: 256px;

  display: flex;
  justify-content: center;
  // object-fit: inital;
`

export default function BoF({ data, location }) {
  const [shareLink, setShareLink] = useState("")
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")

  useEffect(() => {
    try {
      setShareLink(location.state.shareLink)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const copyText = () => {
    let copyText = document.getElementById("shareLinkInput")
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy")
  }

  return (
    <Layout title="Gau Alt-Burgund | Success">
      <Success>
        <h2>Bericht erfolgreich hochgeladen!</h2>
        <StyledImage
          fixed={data.bofLogo.childImageSharp.fixed}
          alt="Logo des Gau Alt-Burgunds"
        />
        <p id={"toUpload"}>
          <i className="las la-angle-double-right" />
          <a href={shareLink} target="_blank" rel={"noopener noreferrer"}>
            Zum Foto-Upload
          </a>
        </p>
        <p id={"linkText"}>
          Oder speichere den Link, um Fotos von einem anderen Gerät/später zu
          ergänzen:
        </p>
        <div>
          <input id={"shareLinkInput"} type="text" value={shareLink} />
          <button onClick={copyText}>
            <i className="las la-copy" /> Link kopieren
          </button>
        </div>
      </Success>
      <Snackbar
        style={{
          zIndex: "99999999",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={() => setSnackOpen(false)}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setSnackOpen(false)}
            href={""}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Layout>
  )
}

// graph for story images
export const StoryImage = graphql`
  fragment ImageUpload on File {
    childImageSharp {
      fixed(width: 256) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

// queries
export const query = graphql`
  query {
    bofLogo: file(relativePath: { eq: "logo-min.png" }) {
      ...ImageUpload
    }
  }
`
